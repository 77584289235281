import { createApp } from 'vue'
import App from './App.vue'
import SlotMachine  from '@lucky-canvas/vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    { path: '/', component: App },
  ]

const router =  createRouter({
    history: createWebHistory(),
routes: routes // (缩写) 相当于 routes: routes
})


const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.use(SlotMachine)
app.mount('#app')
