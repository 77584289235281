<style>
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 600px;
}
</style>

<template>
  <div class="flex">
    <div style="padding: 10px;">
      <el-alert :title="'破壳日抽奖活动 - 剩余抽奖次数:  ' + count" :description="description" type="success" show-icon
        :closable="false" style="margin-bottom: 10px" />

      <SlotMachine @click="startCallback" ref="myLucky" width="340px" height="260px" :prizes="prizes" :blocks="blocks"
        :slots="slots" :defaultStyle="defaultStyle" :defaultConfig="defaultConfig" @end="endCallback" />
    </div>


  </div>

</template>

<script>
import { ElNotification } from 'element-plus'
export default {
  created() {
    this.from = this.getQueryVariable('a')
    this.to = this.getQueryVariable('b')
  },
  data() {
    return {
      from: '',
      to: '',
      count: 4,
      running: false,
      description: "获得奖品（截图找你的男朋友进行兑奖）：",
      blocks: [
        { padding: '10px', background: '#869cfa' },
        { padding: '10px', background: '#e9e8fe' },
      ],
      slots: [
        { order: [5, 1, 2, 3, 4, 0, 6], direction: 1 },
        { order: [5, 2, 3, 4, 1, 0, 6], direction: -1 },
        { order: [5, 3, 4, 2, 0, 1, 6], direction: 1 },
      ],
      prizes: [
        // aj
        {
          background: 'white',
          borderRadius: '10px',
          imgs: [{
            width: '60%',
            top: '20%',
            src: 'https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/9a72c9fc-819e-4c8e-a065-b8291e2d2a60/air-jordan-1-low-se-%E5%A5%B3%E5%AD%90%E8%BF%90%E5%8A%A8%E9%9E%8B-Rs7jj6.png'
          }]
        },
        // hongbao
        {
          background: 'white',
          borderRadius: '10px',
          imgs: [{
            width: '60%',
            top: '20%',
            src: 'https://pic.616pic.com/ys_b_img/00/03/79/SO00gGXfKX.jpg'
          }]
        },
        // just
        {
          background: 'white',
          borderRadius: '10px',
          imgs: [{
            width: '60%',
            top: '20%',
            src: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa1535.phobos.apple.com%2Fus%2Fr30%2FPurple3%2Fv4%2F48%2F6c%2F8c%2F486c8cee-a80f-7a08-7312-30b46fddef68%2Fmzl.fftsveug.png&refer=http%3A%2F%2Fa1535.phobos.apple.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1659876488&t=65a5854c2753dec4dbe462ec68988a79'
          }]
        },
        // kfc
        {
          background: 'white',
          borderRadius: '10px',
          imgs: [{
            width: '60%',
            top: '20%',
            src: 'https://img0.baidu.com/it/u=1937665916,307154246&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1657386000&t=0299fd86c6213fced79774eb8fc4e378'
          }]
        },
        // iphone
        {
          background: 'white',
          borderRadius: '10px',
          imgs: [{
            width: '60%',
            top: '20%',
            src: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2F2a.zol-img.com.cn%2Fproduct%2F215_800x600%2F140%2FceW0p7F7ovEWk.jpg&refer=http%3A%2F%2F2a.zol-img.com.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1660028960&t=f574c8f5d242821696efe8ba158a5236'
          }]
        },
        // xiexie
        {
          background: 'white',
          borderRadius: '10px',
          imgs: [{
            width: '60%',
            top: '20%',
            src: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fmeiyijiagou.cn%2FPublic%2FUploadfiles%2Fc3b85ee92dc549f382bce4d32639df8f.jpg&refer=http%3A%2F%2Fmeiyijiagou.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1659877622&t=001023c326e97e6e5d2ed8ddf607dce5'
          }]
        },
        // maidang
        {
          background: 'white',
          borderRadius: '10px',
          imgs: [{
            width: '60%',
            top: '20%',
            src: 'https://img2.baidu.com/it/u=1427650820,536704507&fm=253&fmt=auto&app=138&f=JPEG?w=660&h=500'
          }]
        },
      ],
      defaultStyle: {
        borderRadius: Infinity,
        background: '#bac5ee',
        fontSize: '32px',
        fontColor: '#333'
      },
      defaultConfig: {
        rowSpacing: '20px',
        colSpacing: '10px'
      },
    }
  },
  methods: {
    getQueryVariable(name){
       let query = window.location.search.substring(1);
       let vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               let pair = vars[i].split("=");
               if(pair[0] == name){return decodeURIComponent(pair[1]);}
       }
       return(false);
    },
    isDuration(beginDateStr, endDateStr) {
      let curDate = new Date();
        let beginDate = new Date(beginDateStr);
        let endDate = new Date(endDateStr);
      if (curDate >= beginDate && curDate <= endDate) {
        return true;
      }
      return false;
    },
    // 点击抽奖按钮会触发star回调
    startCallback() {

      if (this.count <= 0 || this.running) {
        return;
      }
      this.running = true;

      this.count--;

      let res
      let index


      if (this.isDuration(this.from, this.to)) {
        res = [
          [0, 0, 0],
          [1, 1, 1],
          [2, 2, 2],
          [5, 3, 5]
        ]

        index = res[this.count]
      } else {
        //根据当前时间来判断
        res = [
          [5, 5, 5],
          [5, 0, 2],
          [6, 4, 5],
          [1, 2, 3]
        ]
        // 随机取一组数据
        index = res[Math.random() * 4 >> 0]
      }
    



      // else {
      // //根据当前时间来判断
      //   res = [
      //     [5, 5, 5],
      //     [5, 0, 2],
      //     [6, 4, 5],
      //     [1, 2, 3]
      //   ]
      //   // 随机取一组数据
      //   index = res[Math.random() * 4 >> 0]
      // }



      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play()
      // 模拟调用接口异步抽奖
      setTimeout(() => {
  // 假设后端返回的中奖索引是0
  // const index = 0
  // 调用stop停止旋转并传递中奖索引 
  this.$refs.myLucky.stop(index)

  if (index.join() == [2, 2, 2].join()) {
    ElNotification({
      title: '恭喜',
      message: '抽到just dance 游戏卡带',
      type: 'success',
    })
    this.description = this.description + 'just dance 游戏卡带；'
  } else if (index.join() == [1, 1, 1].join()) {

    ElNotification({
      title: '恭喜',
      message: '抽到红包一个',
      type: 'success',
    })

    this.description = this.description + '红包一个；'
  } else if (index.join() == [0, 0, 0].join()) {

    ElNotification({
      title: '恭喜',
      message: '抽到AJ 一双',
      type: 'success',
    })
    this.description = this.description + 'AJ 一双'
  } else {
    ElNotification({
      title: '很遗憾',
      message: '奖品与你擦肩而过',
      type: 'warning',
    })
  }


  // this.running = false;
}, 3000)
    },
// 抽奖结束会触发end回调
endCallback() {
  this.running = false;
},
  }
}
</script>